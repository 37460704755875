body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  font-family: "Verdana", cursive, sans-serif;
  color: #ffffff;
}

body {
  background-color: #7cc6fe;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-size: 16px;
  line-height: 24px;
}

.game-start-container {
  background-color: #252d4a;
  width: 600px;
  min-height: 200px;
  height: min-content;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
}

.game-container {
  background-color: #252d4a;
  width: 450px;
  min-height: 200px;
  height: min-content;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);

}

.score {
  width: 100%;
  position: relative;
}

.lives {
  width: 100%;
  position: relative;
  text-align: right;
}

.container {
  padding-top: 20px;
  display: flex;
  justify-content: space-evenly;
}


/* QUESTION/TIMER/LEFT SECTION */
.question-section {
  width: 100%;
  position: relative;
}

.question-count {
  margin-bottom: 20px;
}

.timer-section {
  width: 100%;
  position: relative;
  margin-bottom: 12px;
}

.timer-text {
  background: rgb(230, 153, 12);
  padding: 15px;
  margin-top: 20px;
  margin-right: 20px;
  border: 5px solid rgb(255, 189, 67);
  border-radius: 15px;
  text-align: center;
}

/* ANSWERS/RIGHT SECTION */
.answer-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

button, button.btn {
  font-size: 16px;
  color: #ffffff;
  background-color: #252d4a;
  border-radius: 15px;
  display: flex;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  border: 5px solid #234668;
  cursor: pointer;
}
button.answer {
  width: 100%;
}

button.correct, button:hover.correct {
  background-color: #2f922f;
}

button.incorrect, button:hover.incorrect {
  background-color: #ff3333;
}

button:hover {
  background-color: #555e7d;
}

button:focus {
  outline: none;
}

button svg {
  margin-right: 5px;
}

.timer {
  grid-area: timer;
}

.question {
  font-size: 48px;
  line-height: 72px;
}

.answers-container {
  grid-area: answers;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.number {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block; /* Add display inline-block to enable padding */
  padding: 10px; /* Add padding to create space between number and circle */
  margin: 5px;
  border-radius: 50%; /* Add border-radius to create a circle */
  background-color: #333; /* Set background-color to black */
  color: white; /* Set color to white */
  font-size: 10px;
  line-height: 1; /* Set line-height to 1 to vertically center number */
}

.answers-table > tr > td.non-bordered {
  border: none;
}

ul > li.scores {
  list-style: none;
}

@media screen and (max-width: 768px) {
  body {
    background-color: #252d4a;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    font-size: 12px;
    line-height: 18px;
  }

  .game-container {
    width: 80%;
    margin: 0 auto;
    height: min-content;
    border: none;
    border-radius: 0;
    padding: 20px;
    box-shadow: none;

  }

  .game-start-container {
    width: 80%;
    margin: 0 auto;
    height: min-content;
    border: none;
    border-radius: 0;
    padding: 20px;
    box-shadow: none;
  }
}
